import global from "../../components/styles/global.module.scss";

import numeral from "numeral";
import Axios from "axios";
import { FUTURE_OPTIONS, ucFirst, UPGRADE_ACCOUNT_URL } from "../../utils/constants/app-constants";

import AircraftFlightHistory from "../../components/shared/aircraftFlightHistory";
import InsightsCard from "../../components/shared/insightsCard";
// import SimpleTableCard from "../../components/shared/simpleTableCard";

import FlightActivity from "../../components/SingleAircraft/FlightActivity";
import StatDonuts from "../../components/SingleAircraft/statDonuts";
import AircraftsTechnicalInfoCard from "../../components/SingleAircraft/AircraftsTechnicalInfoCard";
import KeyFacts from "../../components/SingleAircraft/KeyFacts";
import PerformanceData from "../../components/SingleAircraft/PerformanceData";
import OwnershipCosts from "../../components/SingleAircraft/OwnershipCosts";
import Acquisition from "../../components/SingleAircraft/Acquisition";
import RangeMap from "../../components/SingleAircraft/RangeMap";
import Similar from "../../components/SingleAircraft/Similar";
import AllAircraftCard from "../../components/SingleAircraft/allAircraftCard";
// import TopRoutes from "../../components/shared/topRoutes";

// import FlightsByAirport from "../../components/dashboard/flightsByAirport";

import apiService from "../../services/api";

import { useSearchParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import {
  REGION_OPTIONS,
  CURRENCY_OPTIONS,
  UNIT_OPTIONS,
} from "../../utils/constants/app-constants";
import useUserAuthentication from "../../custom-hooks/useUserAuthentication";
import TopAirports from "../../components/SingleAircraft/TopAirports";

export default function SingleAircraftDetails({defaultPageData, defaultSearchParmasString, aircraftID=null, allowComparison = true,isSampleAircraft=false, readOnly=false }) {
  allowComparison = readOnly ? false : allowComparison
  const { isAuthenticating, isAuthenticated, userLevel } =
    useUserAuthentication();
  const hasLimitedAccess = ['pro_jet_finder','unlimited_jet_finder','jet_finder'].includes(userLevel); 
  const shouldHideTopAirports = userLevel === 'technical_annual' || userLevel === 'new_sep_mid' || userLevel === 'buyer_annual' || userLevel === 'jet_finder_annual';
  const HideCostData = userLevel === 'technical_annual';
  const [fetching, setFetching] = useState(false);
  const [aircraftData, setAircraftData] = useState([]);
  const [similarAircrafts, setSimilarAircrafts] = useState([]);
  const [currency, setCurrency] = useState(CURRENCY_OPTIONS[0]);
  const [region, setRegion] = useState(REGION_OPTIONS[0]);
  const [unit, setUnit] = useState(UNIT_OPTIONS[0]);
  const [nbHours, setNbHours] = useState(defaultPageData?.nbHours || 0);
  const [rangelatLng, setRangeLatLong] = useState(defaultPageData?.rangelatLng || { lat: 37.772, lng: -80 });
  const [rangeaddress, setRangeaddress] = useState(defaultPageData?.rangeaddress || "");
  const [acquisitionAirframe, setAacquisitionAirframe] = useState(defaultPageData?.acquisitionAirframe || 500);
  const [yearManufacture, setYearManufacture] = useState(defaultPageData?.yearManufacture || "Select");
  const [estimatedFutureValue, setestimatedFutureValue] = useState(defaultPageData?.estimatedFutureValue || FUTURE_OPTIONS[0]);
  const [aircraftCostValues, setAircraftCostValues] = useState(defaultPageData?.aircraftCostValues || {});
  const [searchParams] = useSearchParams();
  const id = aircraftID || new URLSearchParams(defaultSearchParmasString).get('id') || searchParams.get("id");
  const [regNumbers, setRegNumbers] = useState("");
  const [flightHistoryData, setFlightHistoryData] = useState([]);
  const [initiatedFollowUp, setInitiatedFollowUp] = useState(false);
  const [regionPrefix, setRegionPrefix] = useState("NA");
  const [conversionRate, setConversionRate] = useState(1);
  const [currencySymbol, setCurrencySymbol] = useState("$");
  const [hasAttemptedHistoryFetch, setHasAttemptedHistoryFetch] = useState(false);
  const [showAllAircraft, setShowAllAircraft] = useState(false);
  const toggleShowAllAircraft = () => setShowAllAircraft(!showAllAircraft);

  useEffect(() => {
    setCurrencySymbol(currency === "USD" ? "$" : currency === "GBP" ? "£" : "€");

    if (currency !== "USD") {
      Axios.get(`https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/usd.json`)
      .then((res) => {
        setConversionRate(res[currency.toLowerCase()]);
      });
    }
  }, [currency]);

  useEffect(() => {
    if (region === "North America") {
      setRegionPrefix("NA");
    } else if (region === "South America") {
      setRegionPrefix("SA");
    } else if (region === "Europe") {
      setRegionPrefix("EU");
    } else {
      setRegionPrefix("AS");
    }
  }, [region]);

  const onCurrencyChanged = (val) => {
    setCurrency(val);
  };

  const onUnitChanged = (val) => {
    setUnit(val);
  };

  const onregionChanged = (val) => {
    setRegion(val);
  };

  const [distanceUnit, setDistanceUnit] = useState("m");
  const [weightUnit, setWeightUnit] = useState("kg");
  useEffect(() => { 
    unit === "Imperial Units" ? setDistanceUnit("ft") : setDistanceUnit("m");
    unit === "Imperial Units" ? setWeightUnit("lbs") : setWeightUnit("kg");
  }, [unit]);

  useEffect(() => {
    // Scroll the content-container to the top
    const contentContainer = document.getElementById('content-container');
    if (contentContainer) {
      contentContainer.scrollTo(0, 0);
    }

    async function fetchData() {
      if (!id) return;
      setFetching(true);
      const response = await apiService.getAircrafts("aircraftIds=" + id);
      if (response.data[0] && Object.keys(response.data[0]).length > 0) {
        setAircraftData(response.data[0]);
      }
    }

    fetchData();
  }, [id]);

  useEffect(() => {
    async function fetchFollowUpData() {
      if (initiatedFollowUp || aircraftData.length === 0) return;
      if (aircraftData.length > 0 && !initiatedFollowUp) setInitiatedFollowUp(true);

      const similarItemsRequest = await apiService.getAircrafts("category=" + aircraftData.category);
      if (similarItemsRequest.data.length > 0) setSimilarAircrafts(similarItemsRequest.data);

      let regQuery = "make=" + aircraftData.aircraft_manufacturer + "&model=" + aircraftData.model;
      //console.log("Using regQuery", regQuery)

      const regNumbers = await apiService.getRegNumbersForAircraft(regQuery);
      //console.log(regNumbers)
      
      if (regNumbers.data.length > 0) {
        let regs = regNumbers.data.join("&reg=");
        setRegNumbers(regs);

        const flightHistory = await apiService.getFlightHoursHistory("",regNumbers.data);
        console.log(flightHistory)
        setFlightHistoryData(flightHistory.data);
      }
      setFetching(false);

      if (!hasAttemptedHistoryFetch) setHasAttemptedHistoryFetch(true);
    }

    fetchFollowUpData();
  }, [aircraftData, initiatedFollowUp]);
  const isImperialUnit =  unit === "Imperial Units";
  const weightPrefix = isImperialUnit ? "lbs" : "kgs";
  return (
    <>
    {fetching && 
      <div className="h-screen flex flex-col justify-center items-center">
        <i className="fa fa-spinner fa-spin text-6xl"></i>
        <h3 className="text-3xl mt-6">Loading Data...</h3>
      </div>
    }

    {!fetching && (
      (Object.keys(aircraftData).length === 0)
      ? <div className="h-screen flex flex-col justify-center items-center">
          <i className="fa fa-solid fa-plane-circle-exclamation text-4xl"></i>
          <h3 className="text-xl mt-6">No Data Available.</h3>
        </div>
      : <>
          {
            isSampleAircraft ? (
              <div className="text-center">
                <p>
                  <b>Note:</b> This is a sample aircraft page. <a className='px-2 py-1 rounded-lg justify-center items-center gap-2 !bg-[#907EFF] !text-white text-center capitalize text-s' href="https://compareprivateplanes.com/pricing-annual">Select a plan</a> to upgrade and unlock over 140 models.
                </p>
              </div>
            ):""
          }
          <KeyFacts 
            params={aircraftData} 
            unit={unit}
            region={region}
            currency={currency}
            onUnitChanged={onUnitChanged}
            onregionChanged={onregionChanged}
            onCurrencyChanged ={onCurrencyChanged }
            pageData={{
              nbHours,
              rangeaddress,
              rangelatLng,
              acquisitionAirframe,
              estimatedFutureValue,
              yearManufacture,
              aircraftCostValues
            }}
            allowComparison={allowComparison}
            readOnly={readOnly}
            regionPrefix={regionPrefix}
            conversionRate={conversionRate}
            currencySymbol={currencySymbol}
          />

          {/* <AircraftFlightHistory regNumbers={regNumbers} /> */}
          <PerformanceData params={aircraftData} unit={unit} hasLimitedAccess={hasLimitedAccess} />
          <RangeMap params={aircraftData} address={rangeaddress} setAddress={setRangeaddress} latLng={rangelatLng} setLatLong={setRangeLatLong} />
          {!HideCostData && (<OwnershipCosts
            params={aircraftData}
            currencySymbol={currencySymbol}
            region={region}
            conversionRate={conversionRate}
            setNbHours={setNbHours}
            nbHours={nbHours}
            aircraftCostValues={aircraftCostValues}
            setAircraftCostValues={setAircraftCostValues}
            hasLimitedAccess={hasLimitedAccess}
            readOnly={readOnly}
          />)}

{!HideCostData && (<Acquisition
            airframe={acquisitionAirframe}
            setAirframe={setAacquisitionAirframe}
            yearManufacture={yearManufacture}
            setYearManufacture={setYearManufacture}
            estimatedFutureValue={estimatedFutureValue}
            setestimatedFutureValue={setestimatedFutureValue}
            params={aircraftData}
            conversionRate={conversionRate}
            currencySymbol={currencySymbol}
            hasLimitedAccess={hasLimitedAccess}
            readOnly={readOnly}
          />)}
          {/* {!shouldHideTopAirports && (<FlightActivity preData={flightHistoryData} regNumbers={regNumbers} toggleShowAllAircraft={toggleShowAllAircraft} hasLimitedAccess={hasLimitedAccess}/>)}
          {!shouldHideTopAirports && (<TopAirports title="Top Airports" presetFilters={`reg=${regNumbers}`} hasLimitedAccess={hasLimitedAccess}/>)} */}

          <div id="technical" className="flex flex-col gap-16">
            <h3 className="heading-large">Technical Specifications</h3>
            <AircraftsTechnicalInfoCard
              title="Features"
              stats={[
                {
                title:"Minimum Pilots",
                content: aircraftData.minimum_pilots,
                blur: hasLimitedAccess
                },{
                title:"Toilet Available",
                content: aircraftData.toilet ? "Yes" : "No",
                blur: hasLimitedAccess
                },{
                title:"Shower Available",
                content: aircraftData.shower ? "Yes" : "No",
                blur: hasLimitedAccess
                },{
                title:"Space to Sleep",
                content: aircraftData.space_to_sleep ? "Yes" : "No",
                blur: hasLimitedAccess
                },{
                title:"Typical Avionics Suite",
                content: aircraftData.typical_avionic_suite,
                blur: hasLimitedAccess
                },{
                title:"Flat Floor",
                content: aircraftData.flat_floor ? "Yes" : "No",
                blur: hasLimitedAccess
                },{
                title:"Inflight Baggage Access",
                content: aircraftData.baggage_access ? "Yes" : "No",
                blur: hasLimitedAccess
                },{
                title:"Dedicated Bedroom",
                content: aircraftData.dedicated_bed ? "Yes" : "No",
                blur: hasLimitedAccess
                },
              ]}
            />
            <AircraftsTechnicalInfoCard
              title="Interior"
              stats={[
                {
                title:"Maximum Passengers",
                content: aircraftData.max_pax,
                blur: hasLimitedAccess
                },{
                title:"Typical Passengers",
                content: aircraftData.typical_pax,
                blur: hasLimitedAccess
                },{
                title:"Number of Living Zones",
                content: aircraftData.living_zones,
                blur: hasLimitedAccess
                },{
                title:"Cabin Noise (dB)",
                content: aircraftData.cabin_noise,
                blur: hasLimitedAccess
                },{
                title:"Max Cabin Altitude",
                content: `${numeral(aircraftData[`max_altitude_${unit === "Imperial Units" ? "feet" : "meters"}`]).format("0,0")} ${unit === "Imperial Units" ? "feet" : "meters"}`,
                blur: hasLimitedAccess
                },{
                title:"Sea Level Cabin",
                content: `${numeral(aircraftData.sea_level_cabin).format("0,0")} ${unit === "Imperial Units" ? "feet" : "meters"}`,
                blur: hasLimitedAccess
                },{
                title:"Pressure Differential",
                content: `${aircraftData.pressure_differential_psi} PSI`,
                blur: hasLimitedAccess
                },{
                title:"Cabin Volume",
                content: `${aircraftData[`cabin_volume_${unit === "Imperial Units" ? "CF" : "cubicmeters"}`]} cubic ${unit === "Imperial Units" ? "feet" : "meters"}`,
                blur: hasLimitedAccess
                },
              ]}
            />
            <AircraftsTechnicalInfoCard
              title="Powerplant"
              stats={[
                {
                title:"Engine Make",
                content: aircraftData.engine_manufacturer,
                },{
                title: `Thrust per Engine (${weightUnit})`,
                content: numeral(aircraftData[`thrust_output_${unit === "Imperial Units" ? "lbs" : "kgs"}`]).format("0,0"),
                },{
                title:"Engine Model",
                content: aircraftData.engine_model,
                },{
                title: `Total Thrust Output (${weightUnit})`,
                content: numeral(aircraftData[`total_thrust_${unit === "Imperial Units" ? "lbs" : "kgs"}`]).format("0,0"),
                },{
                title: `Exterior Noise Levels`,
                content: [
                  {
                    title: 'Lateral',
                    content: `${numeral(aircraftData.lateral_db).format("0,0")} dB`,
                  },
                  {
                    title: 'Flyover',
                    content: `${numeral(aircraftData.flyover_db).format("0,0")} dB`,
                  },
                  {
                    title: 'Approach',
                    content: `${numeral(aircraftData.approach_db).format("0,0")} dB`,
                  },
                ],
                },
              ]}
            />          
            <AircraftsTechnicalInfoCard
              title="Weights"
              stats={[
                {
                title:`Max Take-Off Weight`,
                content: `${numeral(aircraftData[`MTOW_${weightPrefix}`]).format("0,0")} ${weightPrefix}`,
                },{
                title:`Max Landing Weight`,
                content: `${numeral(aircraftData[`max_landing_weight_${weightPrefix}`]).format("0,0")} ${weightPrefix}`,
                },{
                title:`Available Fuel`,
                content: `${numeral(aircraftData[`available_fuel_${weightPrefix}`]).format("0,0")} ${weightPrefix}`,
                blur:hasLimitedAccess
                },{
                title:`Basic Operating Weight`,
                content: `${numeral(aircraftData[`basic_operating_weight_${weightPrefix}`]).format("0,0")} ${weightPrefix}`,
                blur:hasLimitedAccess
                },{
                title:`Max Ramp Weight`,
                content: `${numeral(aircraftData[`max_ramp_weight_${weightPrefix}`]).format("0,0")} ${weightPrefix}`,
                blur:hasLimitedAccess
                },{
                title:`Available Fuel`,
                content: `${numeral(aircraftData[`available_fuel_${weightPrefix}`]).format("0,0")} ${weightPrefix}`,
                blur:hasLimitedAccess
                },{
                title:`Useful Payload`,
                content: `${weightPrefix === "lbs" ? numeral(aircraftData.useful_load_lbs).format("0,0") : numeral(aircraftData.useful_payloads_kgs).format("0,0")} ${weightPrefix}`,
                blur:hasLimitedAccess
                },{
                title:`Baggage Weight`,
                content: `${numeral(aircraftData[`baggage_weight_${weightPrefix}`]).format("0,0")} ${weightPrefix}`,
                blur:hasLimitedAccess
                },{
                title: `Baggage Capacity`,
                content: [
                  {
                    title: 'Total',
                    content: `${aircraftData[`baggage_capacity_${isImperialUnit ? "CF" : "cubicmeters"}`]} ${isImperialUnit ? "Cubic Feet" : "Cubic Meters"}`,
                  },
                  {
                    title: 'Internal',
                    content: `${aircraftData[`internal_baggage_${isImperialUnit ? "CF" : "cubicmeters"}`]} ${isImperialUnit ? "Cubic Feet" : "Cubic Meters"}`,
                  },
                  {
                    title: 'External',
                    content: `${aircraftData[`external_baggage_${isImperialUnit ? "CF" : "cubicmeters"}`]} ${isImperialUnit ? "Cubic Feet" : "Cubic Meters"}`,
                  },
                ],
                },
              ]}
            />       
            <AircraftsTechnicalInfoCard
              title="Dimensions"
              stats={[
                {
                title: `Exterior Dimensions`,
                content: [
                  {
                    title: 'Length',
                    content: `${aircraftData[`ext_length_${isImperialUnit ? "feet" : "meters"}`]} ${isImperialUnit ? "Feet" : "Meters"}`,
                  },
                  {
                    title: 'Height',
                    content: `${aircraftData[`exterior_height_${isImperialUnit ? "feet" : "meters"}`]} ${isImperialUnit ? "Feet" : "Meters"}`,
                  },
                  {
                    title: 'Wingspan',
                    content: `${aircraftData[`wingspan_${isImperialUnit ? "feet" : "meters"}`]} ${isImperialUnit ? "Feet" : "Meters"}`,
                  },
                ],
                },
                {
                title: `Interior Dimensions`,
                content: [
                  {
                    title: 'Length',
                    content: `${aircraftData[`int_length_${isImperialUnit ? "feet" : "meters"}`]} ${isImperialUnit ? "Feet" : "Meters"}`,
                  },
                  {
                    title: 'Height',
                    content: `${aircraftData[`int_height_${isImperialUnit ? "feet" : "meters"}`]} ${isImperialUnit ? "Feet" : "Meters"}`,
                  },
                  {
                    title: 'Width',
                    content: `${aircraftData[`int_width_${isImperialUnit ? "feet" : "meters"}`]} ${isImperialUnit ? "Feet" : "Meters"}`,
                  },
                ],
                },
                {
                title: `Door Dimensions`,
                content: [
                  {
                    title: 'Height',
                    content: `${aircraftData[`door_height_${isImperialUnit ? "feet" : "meters"}`]} ${isImperialUnit ? "Feet" : "Meters"}`,
                  },
                  {
                    title: 'Width',
                    content: `${aircraftData[`door_width_${isImperialUnit ? "feet" : "meters"}`]} ${isImperialUnit ? "Feet" : "Meters"}`,
                  },
                ],
                },
              ]}
            />
          </div>

          {/* <TopRoutes presetFilters={"reg=" + regNumbers}/> */}

          {/*<div className="grid lg:grid-cols-2 md:gap-6 gap-8">
            {aircraftData.aircraft_manufacturer && aircraftData.model && (
              <>
                <SimpleTableCard
                  title="Transaction History"
                  apiRoute="getRegistrations"
                  additionalApiFilters={`make=${aircraftData.aircraft_manufacturer}&model=${aircraftData.model}`}
                  timePeriod={1000}
                />

                <SimpleTableCard
                  title="Accident History"
                  apiRoute="getAccidents"
                  additionalApiFilters={"reg=" + regNumbers}
                  timePeriod={1000}
                />
              </>
            )}
          </div>*/}


          {!readOnly && <Similar params={similarAircrafts.filter((aircraft) => aircraft.id !== aircraftData.id)}/>}
        </>
    )}
    </>
  );
}
