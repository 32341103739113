import axios, { isAxiosError } from 'axios'
import React, { useState, useEffect, useRef, useContext, useMemo } from 'react'
import { API_URL } from '../../utils/constants/app-constants'
import { toast } from 'react-toastify'
import AppContext from '../../custom-context/appContext'
import useUserAuthentication from "../../custom-hooks/useUserAuthentication"
import {
  completeSharedLink,
  handleCopyOnClipboard
} from '../../utils/functions'

const Input = ({ setValue, value, type, placeholder, disabled }) => {
  return (
    <input
      disabled={disabled}
      type={type || 'text'}
      placeholder={placeholder}
      value={value}
      onChange={e => setValue(e.target.value)}
      className='w-full border border-cetaceanBlue rounded-lg text-base py-1 px-2'
    />
  )
}
const CreateShareLinkModal = ({ pageType, searchParams, pageData }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const modalRef = useRef()
  const [customerName, setCustomerName] = useState('')
  const [password, setPassword] = useState('')
  const [isCreatingLink, setIsCreatingLink] = useState('')
  const [shareId, setShareId] = useState('')
  const { loggedInUsername } = useContext(AppContext)

  const { userLevel } = useUserAuthentication();
  const shouldHideCreateShareLink = userLevel === 'technical_annual';  

  const handleCreateLink = async e => {
    e.preventDefault()
    if (!customerName || !password) return
    setIsCreatingLink(true)
    try {
      const res = await axios.post(
        `${API_URL}create-shared-link/?username=${loggedInUsername}`,
        {
          page_type: pageType,
          page_data: pageData,
          search_params: searchParams,
          customer_name: customerName,
          password
        }
      )
      if (res.status == '200') {
        setShareId(res.data?.share_id)
        setPassword('')
        setCustomerName('')
      } else {
        toast.error(res.data.error)
      }
    } catch (error) {
      let message = 'Something went wrong!'
      if (isAxiosError(error)) {
        message = error.response.data.error
      }
      toast.error(message)
    } finally {
      setIsCreatingLink(false)
    }
  }

  useEffect(() => {
    const closeModalOnClickOutside = event => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShareId('')
        setModalOpen(false)
      }
    }
    document.addEventListener('mousedown', closeModalOnClickOutside)
    return () => {
      document.removeEventListener('mousedown', closeModalOnClickOutside)
    }
  }, [])
  return (
    <>
    {!shouldHideCreateShareLink && (
      <button
        className='py-2 px-4 bg-cetaceanBlue text-white rounded-full'
        onClick={() => setModalOpen(true)}
      >
        Create Share Link
      </button>
      )}

      {modalOpen && (
        <div className='modal-container p-6'>
          <div className='card max-h-[80vh] w-[min(100%,500px)]' ref={modalRef}>
            {shareId ? (
              <div className='text-center space-y-4'>
                <h2 className='text-xl font-semibold'>Link Created</h2>
                <input
                  type='text'
                  readOnly
                  value={completeSharedLink(shareId)}
                  className='w-full border border-gray-300 rounded-lg py-2 px-4 text-center cursor-pointer'
                  onClick={() =>
                    handleCopyOnClipboard(
                      completeSharedLink(shareId),
                      'Link Copied!'
                    )
                  }
                />
                <p>
                  <a
                    href={`/shared-links`}
                    className='hover:underline font-bold'
                  >
                    Click here
                  </a>{' '}
                  to see all shared links.
                </p>
              </div>
            ) : (
              <div>
                <h2 className='text-2xl font-semibold mb-6'>
                  Create a Shareable Link
                </h2>
                <p>Any changes made to the page will be saved. Links are valid for 30 days.</p><p> </p>
                <form className='space-y-3' onSubmit={handleCreateLink}>
                  <Input
                    disabled={isCreatingLink}
                    placeholder='Customer Name'
                    value={customerName}
                    setValue={setCustomerName}
                  />
                  <Input
                    disabled={isCreatingLink}
                    placeholder='Password'
                    value={password}
                    setValue={setPassword}
                  />
                  <button
                    disabled={isCreatingLink || !customerName || !password}
                    className='py-2 px-4 bg-cetaceanBlue text-white rounded-lg w-full flex justify-center items-center gap-2'
                  >
                    {isCreatingLink && (
                      <i className='fa-solid fa-spinner animate-spin' />
                    )}{' '}
                    <span>Create</span>
                  </button>
                </form>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default CreateShareLinkModal
