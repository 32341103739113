export const AUTHENTICATION_COOKIE_KEY = "wc_loggedin_user";

const CATEGORY_OPTIONS = ["", "Large", "Medium", "Light", "VLJ"];
const AIRFRAME_OPTIONS = [
  "500 hours",
  "1000 hours",
  "1500 hours",
  "2000 hours",
  "2500 hours",
  "3000 hours",
];

const FUTURE_OPTIONS = ["1 year", "2 years", "3 years", "5 years", "10 years"];

const CATEGORY_OPTIONS_DIC = {
  "": "Show all",
  Large: "Large",
  Medium: "Medium",
  Light: "Light",
  VLJ: "VLJ",
};

const CLASS_OPTIONS = ["VLJ", "Light", "Medium", "Large"];
const MAKE_OPTIONS = [
  "Beechcraft",
  "Beechjet",
  "Bombardier",
  "Cessna",
  "Cirrus",
  "Dassault",
  "Eclipse",
  "Embraer",
  "Gulfstream",
  "Hawker",
  "Honda",
  "IAI",
  "Mitsubishi",
  "Nextant",
  "Pilatus"
];
const MODEL_OPTIONS = [
  "1000", "4000", "400XP", "400XPR", "400XT", "400XTi", "604XT", "700", "750", "800A", "800SP", "800XP", "800XPi", "850XP",
  "Challenger 300", "Challenger 350", "Challenger 600", "Challenger 601-1A", "Challenger 601-3A", "Challenger 601-3AER", "Challenger 601-3R", "Challenger 604", "Challenger 605",
  "Challenger 650", "Challenger 850", "Citation Bravo", "Citation CJ1", "Citation CJ1+", "Citation CJ2", "Citation CJ2+", "Citation CJ3", "Citation CJ3+", "Citation CJ4", 
  "Citation Encore", "Citation Encore+", "Citation Excel", "Citation I", "Citation II", "Citation III", "Citation Latitude", "Citation Longitude", "Citation M2", "Citation Mustang", 
  "Citation SII", "Citation Sovereign", "Citation Sovereign+", "Citation V", "Citation V Ultra", "Citation VI", "Citation VII", "Citation X", "Citation X+", "Citation XLS", 
  "Citation XLS+", "Diamond 1A", "Eclipse 500", "Eclipse 550", "Elite S", "Falcon 10", "Falcon 100", "Falcon 200", "Falcon 2000", "Falcon 2000DX", "Falcon 2000EX", 
  "Falcon 2000EX EASy", "Falcon 2000LX", "Falcon 2000LXS", "Falcon 2000S", "Falcon 20F-5BR", "Falcon 50", "Falcon 50-40", "Falcon 50EX", "Falcon 6X", "Falcon 7X", "Falcon 8X", 
  "Falcon 900", "Falcon 900B", "Falcon 900C", "Falcon 900DX", "Falcon 900EX", "Falcon 900EX EASy", "Falcon 900LX", "G100", "G150", "G200", "G280", "G300", "G350", "G400", 
  "G450", "G500", "G550", "G600", "G650", "G650ER", "G700", "G800", "GII", "GIII", "GIV", "GIVSP", "GV", "Global 5000", "Global 5500", "Global 6000", "Global 6500", 
  "Global 7500", "Global Express", "Global Express XRS", "HA-420", "Learjet 31", "Learjet 31A", "Learjet 31AER", "Learjet 35A", "Learjet 36A", 
  "Learjet 40", "Learjet 40XR", "Learjet 45", "Learjet 45XR", "Learjet 55", "Learjet 55C", "Learjet 60", "Learjet 60XR", "Learjet 70", "Learjet 75", "Learjet 75 Liberty", 
  "Legacy 450", "Legacy 500", "Legacy 600", "Legacy 650", "Legacy 650E", "Lineage 1000", "Lineage 1000E", "PC-24", "Phenom 100", "Phenom 100E", "Phenom 100EV", 
  "Phenom 300", "Phenom 300E", "Praetor 500", "Praetor 600", "Premier I", "Premier IA", "Vision Jet SF50", "Westwind 1", "Westwind 2"
];

const AIRCRAFT_REGNUMBER_COUNTRY_PREFIXES = Object.entries({
  "YA": "Afghanistan",
  "ZA": "Albania",
  "7T": "Algeria",
  "C3": "Andorra",
  "D2": "Angola",
  "VP-A": "Anguilla",
  "V2": "Antigua and Barbuda",
  "LV": "Argentina",
  "LQ": "Argentina",
  "EK": "Armenia",
  "P4": "Aruba",
  "VH": "Australia",
  "OE": "Austria",
  "4K": "Azerbaijan",
  "C6": "Bahamas",
  "A9C": "Bahrain",
  "S2": "Bangladesh",
  "8P": "Barbados",
  "EW": "Belarus",
  "OO": "Belgium",
  "V3": "Belize",
  "TY": "Benin",
  "VP-B": "Bermuda",
  "A5": "Bhutan",
  "CP": "Bolivia",
  "E7": "Bosnia and Herzegovina",
  "A2": "Botswana",
  "PP": "Brazil",
  "PR": "Brazil",
  "PS": "Brazil",
  "PT": "Brazil",
  "PU": "Brazil",
  "VP-L": "British Virgin Islands",
  "V8": "Brunei",
  "LZ": "Bulgaria",
  "XT": "Burkina Faso",
  "9U": "Burundi",
  "XU": "Cambodia",
  "TJ": "Cameroon",
  "C": "Canada",
  "D4": "Cape Verde",
  "VP-C": "Cayman Islands",
  "TL": "Central African Republic",
  "TT": "Chad",
  "CC": "Chile",
  "B": "China",
  "HJ": "Colombia",
  "HK": "Colombia",
  "D6": "Comoros",
  "TN": "Congo, Republic of",
  "E5": "Cook Islands",
  "9S": "Congo, Democratic Republic of",
  "TI": "Costa Rica",
  "TU": "Côte d'Ivoire",
  "9A": "Croatia",
  "CU": "Cuba",
  "5B": "Cyprus, Republic of",
  "OK": "Czech Republic",
  "OY": "Denmark",
  "J2": "Djibouti",
  "J7": "Dominica",
  "HI": "Dominican Republic",
  "4W": "East Timor",
  "HC": "Ecuador",
  "SU": "Egypt",
  "YS": "El Salvador",
  "3C": "Equatorial Guinea",
  "E3": "Eritrea",
  "ES": "Estonia",
  "3DC": "Eswatini (formerly Swaziland)",
  "ET": "Ethiopia",
  "VP-F": "Falkland Islands",
  "DQ": "Fiji Islands",
  "OH": "Finland",
  "F": "France",
  "F-OG": "French West Indies",
  "F-O": "French Guiana",
  "TR": "Gabon",
  "C5": "Gambia",
  "4L": "Georgia",
  "D": "Germany",
  "9G": "Ghana",
  "VP-G": "Gibraltar",
  "SX": "Greece",
  "J3": "Grenada",
  "TG": "Guatemala",
  "2": "Guernsey",
  "3X": "Guinea",
  "J5": "Guinea Bissau",
  "8R": "Guyana",
  "HH": "Haiti",
  "HR": "Honduras",
  "B-H": "Hong Kong",
  "B-K": "Hong Kong",
  "B-L": "Hong Kong",
  "HA": "Hungary",
  "TF": "Iceland",
  "VT": "India",
  "PK": "Indonesia",
  "EP": "Iran",
  "YI": "Iraq",
  "EI": "Ireland",
  "4X": "Israel",
  "I": "Italy",
  "6Y": "Jamaica",
  "JA": "Japan",
  "ZJ": "Jersey",
  "JY": "Jordan",
  "Z6": "Kosovo, Republic of",
  "UP": "Kazakhstan",
  "5Y": "Kenya",
  "T3": "Kiribati",
  "P": "Korea, Democratic People's Republic of",
  "HL": "Korea, Republic of",
  "9K": "Kuwait",
  "EX": "Kyrgyzstan",
  "RDPL": "Laos",
  "YL": "Latvia",
  "OD": "Lebanon",
  "7P": "Lesotho",
  "A8": "Liberia",
  "5A": "Libya",
  "HB": "Liechtenstein",
  "LY": "Lithuania",
  "LX": "Luxembourg",
  "B-M": "Macau",
  "5R": "Madagascar",
  "7Q": "Malawi",
  "9M": "Malaysia",
  "8Q": "Maldives",
  "TZ": "Mali",
  "9H": "Malta",
  "M": "Isle of Man",
  "V7": "Marshall Islands",
  "F-OM": "Martinique",
  "5T": "Mauritania",
  "3B": "Mauritius",
  "XA": "Mexico",
  "XB": "Mexico",
  "XC": "Mexico",
  "V6": "Micronesia, Federated States of",
  "ER": "Moldova",
  "3A": "Monaco",
  "JU": "Mongolia",
  "4O": "Montenegro",
  "VP-M": "Montserrat",
  "CN": "Morocco",
  "C9": "Mozambique",
  "XY": "Myanmar",
  "XZ": "Myanmar",
  "V5": "Namibia",
  "C2": "Nauru",
  "9N": "Nepal",
  "PH": "Netherlands",
  "PJ": "Netherlands Antilles",
  "ZK": "New Zealand",
  "ZL": "New Zealand",
  "ZM": "New Zealand",
  "YN": "Nicaragua",
  "5U": "Niger",
  "5N": "Nigeria",
  "Z3": "North Macedonia",
  "LN": "Norway",
  "A4O": "Oman",
  "AP": "Pakistan",
  "SU-Y": "Palestine",
  "E4": "Palestine",
  "HP": "Panama",
  "P2": "Papua New Guinea",
  "ZP": "Paraguay",
  "OB": "Peru",
  "RP": "Philippines",
  "SP": "Poland",
  "SN": "Poland",
  "CR": "Portugal",
  "A7": "Qatar",
  "F-OD": "Réunion Island",
  "YR": "Romania",
  "RA": "Russian Federation",
  "RF": "Russian Federation",
  "9XR": "Rwanda",
  "VQ-H": "Saint Helena/Ascension",
  "V4": "Saint Kitts and Nevis",
  "J6": "Saint Lucia",
  "J8": "Saint Vincent and the Grenadines",
  "5W": "Samoa",
  "T7": "San Marino",
  "S9": "São Tomé and Príncipe",
  "HZ": "Saudi Arabia",
  "6V": "Senegal",
  "6W": "Senegal",
  "YU": "Serbia",
  "S7": "Seychelles",
  "9L": "Sierra Leone",
  "9V": "Singapore",
  "OM": "Slovakia",
  "S5": "Slovenia",
  "H4": "Solomon Islands",
  "6O": "Somalia",
  "ZS": "South Africa",
  "ZT": "South Africa",
  "ZU": "South Africa",
  "Z8": "South Sudan",
  "EC": "Spain",
  "EM": "Spain",
  "4R": "Sri Lanka",
  "ST": "Sudan",
  "PZ": "Suriname",
  "3D": "Swaziland",
  "SE": "Sweden",
  "HB": "Switzerland",
  "YK": "Syria",
  "F-OH": "Tahiti",
  "B": "Taiwan",
  "EY": "Tajikistan",
  "5H": "Tanzania",
  "HS": "Thailand",
  "5V": "Togo",
  "A3": "Tonga",
  "9Y": "Trinidad and Tobago",
  "TS": "Tunisia",
  "TC": "Turkey",
  "EZ": "Turkmenistan",
  "VQ-T": "Turks and Caicos",
  "T2": "Tuvalu",
  "5X": "Uganda",
  "UR": "Ukraine",
  "A6": "United Arab Emirates",
  "G": "United Kingdom",
  "4U": "United Nations",
  "N": "United States of America",
  "CX": "Uruguay",
  "UK": "Uzbekistan",
  "YJ": "Vanuatu",
  "YV": "Venezuela",
  "VN": "Vietnam",
  "7O": "Yemen",
  "9J": "Zambia",
  "Z": "Zimbabwe"
}).sort((a, b) => b[0].length - a[0].length);

function getCountryFromRegistration(registrationNumber) {
  for (const [prefix, country] of AIRCRAFT_REGNUMBER_COUNTRY_PREFIXES) {
    if (registrationNumber.startsWith(prefix)) return country;
  }
  return "Unknown";
}

const MANUFACTURER_OPTIONS = [
  "",
  "Beechcraft",
  "Bombardier",
  "Cessna",
  "Cirrus",
  "Dassault",
  "Eclipse",
  "Embraer",
  "Gulfstream",
  "Hawker",
  "Honda",
  "IAI",
  "Mitsubishi",
  "Nextant",
  "Pilatus",
];

const MONTH_PREFIXES = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const FORMAT_DATE = (dateStr, ignoreYear) => {
  var regex = /^\d{2}\/\d{2}\/\d{4}$/;
  var month, day, year;
  if (regex.test(dateStr)) {
    const dateParts = dateStr.split('/');
    month = parseInt(dateParts[0], 10) - 1; // Month is 0-indexed in JavaScript Date
    day = parseInt(dateParts[1], 10);
    year = dateParts[2];
    
  }else{
    const dateObject = new Date(dateStr);
    year = dateObject.getFullYear();
    month = dateObject.getMonth() + 1;
    day = dateObject.getDate();
  }
    // Determine the ordinal indicator
    let ordinalIndicator;
    switch (day) {
      case 1:
      case 21:
      case 31:
        ordinalIndicator = "st";
        break;
      case 2:
      case 22:
        ordinalIndicator = "nd";
        break;
      case 3:
      case 23:
        ordinalIndicator = "rd";
        break;
      default:
        ordinalIndicator = "th";
    }
  
    // Format the date
    if (ignoreYear) return `${day}${ordinalIndicator} ${MONTH_PREFIXES[month]}`;
    return `${day}${ordinalIndicator} ${MONTH_PREFIXES[month]} ${year}`;
}

const calculateDistance = (startCoords, endCoords) => {
  const [startLat, startLng] = startCoords.map(Number);
  const [endLat, endLng] = endCoords.map(Number);
  const R = 6371;
  const dLat = (endLat - startLat) * Math.PI / 180;
  const dLng = (endLng - startLng) * Math.PI / 180;
  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(startLat * Math.PI / 180) * Math.cos(endLat * Math.PI / 180) *
            Math.sin(dLng / 2) * Math.sin(dLng / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distanceKm = R * c;
  const distanceNm = distanceKm / 1.852;

  return distanceNm;
};

const calculateTimeDifference = (createdAt, updatedAt) => {
  const createdAtTime = new Date(createdAt);
  const updatedAtTime = new Date(updatedAt);
  const timeDifference = updatedAtTime - createdAtTime;
  const timeDifferenceInHours = Math.round(timeDifference / (1000 * 60 * 60));
  return timeDifferenceInHours;
};

const REGION_OPTIONS = ["North America", "Europe", "South America", "Asia"];
const CURRENCY_OPTIONS = ["USD", "EUR", "GBP"];
const UNIT_OPTIONS = ["Imperial Units", "Metric Units"];

const MANUFACTURER_OPTIONS_DIC = {
  "": "Show all",
  Beechcraft: "Beechcraft",
  Bombardier: "Bombardier",
  Cessna: "Cessna",
  Cirrus: "Cirrus",
  Dassault: "Dassault",
  Eclipse: "Eclipse",
  Embraer: "Embraer",
  Gulfstream: "Gulfstream",
  Hawker: "Hawker",
  Honda: "Honda",
  IAI: "IAI",
  Mitsubishi: "Mitsubishi",
  Nextant: "Nextant",
  Pilatus: "Pilatus",
};

const PRODUCTION_OPTIONS = ["", "Yes", "No"];
const PRODUCTION_OPTIONS_DIC = {
  "": "Show all",
  Yes: "Yes",
  No: "No",
};

const ucFirst = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

const FILTER_OPTIONS = [
  { make: "Beechcraft", model: "Premier I", class: "Light" },
  { make: "Beechcraft", model: "Premier IA", class: "Light" },
  { make: "Bombardier", model: "Challenger 300", class: "Large" },
  { make: "Bombardier", model: "Challenger 350", class: "Large" },
  { make: "Bombardier", model: "Challenger 600", class: "Large" },
  { make: "Bombardier", model: "Challenger 601-1A", class: "Large" },
  { make: "Bombardier", model: "Challenger 601-3A", class: "Large" },
  { make: "Bombardier", model: "Challenger 601-3AER", class: "Large" },
  { make: "Bombardier", model: "Challenger 601-3R", class: "Large" },
  { make: "Bombardier", model: "Challenger 604", class: "Large" },
  { make: "Bombardier", model: "Challenger 605", class: "Large" },
  { make: "Bombardier", model: "Challenger 650", class: "Large" },
  { make: "Bombardier", model: "Challenger 850", class: "Large" },
  { make: "Bombardier", model: "Global 5000", class: "Large" },
  { make: "Bombardier", model: "Global 6000", class: "Large" },
  { make: "Bombardier", model: "Global 7500", class: "Large" },
  { make: "Bombardier", model: "Global Express", class: "Large" },
  { make: "Bombardier", model: "Global Express XRS", class: "Large" },
  { make: "Bombardier", model: "Learjet 31", class: "Light" },
  { make: "Bombardier", model: "Learjet 31A", class: "Light" },
  { make: "Bombardier", model: "Learjet 31AER", class: "Light" },
  { make: "Bombardier", model: "Learjet 35A", class: "Light" },
  { make: "Bombardier", model: "Learjet 36A", class: "Light" },
  { make: "Bombardier", model: "Learjet 40", class: "Light" },
  { make: "Bombardier", model: "Learjet 40XR", class: "Light" },
  { make: "Bombardier", model: "Learjet 45", class: "Light" },
  { make: "Bombardier", model: "Learjet 45XR", class: "Light" },
  { make: "Bombardier", model: "Learjet 55", class: "Medium" },
  { make: "Bombardier", model: "Learjet 55C", class: "Medium" },
  { make: "Bombardier", model: "Learjet 60", class: "Medium" },
  { make: "Bombardier", model: "Learjet 60XR", class: "Medium" },
  { make: "Bombardier", model: "Learjet 70", class: "Light" },
  { make: "Bombardier", model: "Learjet 75", class: "Light" },
  { make: "Bombardier", model: "Learjet 75 Liberty", class: "Light" },
  { make: "Cessna", model: "Citation Bravo", class: "Light" },
  { make: "Cessna", model: "Citation CJ1", class: "Light" },
  { make: "Cessna", model: "Citation CJ1+", class: "Light" },
  { make: "Cessna", model: "Citation CJ2", class: "Light" },
  { make: "Cessna", model: "Citation CJ2+", class: "Light" },
  { make: "Cessna", model: "Citation CJ3", class: "Light" },
  { make: "Cessna", model: "Citation CJ3+", class: "Light" },
  { make: "Cessna", model: "Citation CJ4", class: "Light" },
  { make: "Cessna", model: "Citation Encore", class: "Light" },
  { make: "Cessna", model: "Citation Encore+", class: "Light" },
  { make: "Cessna", model: "Citation Excel", class: "Medium" },
  { make: "Cessna", model: "Citation I", class: "Light" },
  { make: "Cessna", model: "Citation II", class: "Light" },
  { make: "Cessna", model: "Citation III", class: "Light" },
  { make: "Cessna", model: "Citation Latitude", class: "Medium" },
  { make: "Cessna", model: "Citation Longitude", class: "Large" },
  { make: "Cessna", model: "Citation M2", class: "VLJ" },
  { make: "Cessna", model: "Citation Mustang", class: "VLJ" },
  { make: "Cessna", model: "Citation SII", class: "Light" },
  { make: "Cessna", model: "Citation Sovereign", class: "Medium" },
  { make: "Cessna", model: "Citation Sovereign+", class: "Medium" },
  { make: "Cessna", model: "Citation V", class: "Medium" },
  { make: "Cessna", model: "Citation V Ultra", class: "Medium" },
  { make: "Cessna", model: "Citation VI", class: "Medium" },
  { make: "Cessna", model: "Citation VII", class: "Medium" },
  { make: "Cessna", model: "Citation X", class: "Large" },
  { make: "Cessna", model: "Citation X+", class: "Large" },
  { make: "Cessna", model: "Citation XLS", class: "Medium" },
  { make: "Cessna", model: "Citation XLS+", class: "Medium" },
  { make: "Cirrus", model: "Vision Jet SF50", class: "VLJ" },
  { make: "Dassault", model: "Falcon 10", class: "Light" },
  { make: "Dassault", model: "Falcon 100", class: "Light" },
  { make: "Dassault", model: "Falcon 200", class: "Medium" },
  { make: "Dassault", model: "Falcon 2000", class: "Large" },
  { make: "Dassault", model: "Falcon 2000DX", class: "Large" },
  { make: "Dassault", model: "Falcon 2000EX", class: "Large" },
  { make: "Dassault", model: "Falcon 2000EX EASy", class: "Large" },
  { make: "Dassault", model: "Falcon 2000LX", class: "Large" },
  { make: "Dassault", model: "Falcon 2000LXS", class: "Large" },
  { make: "Dassault", model: "Falcon 2000S", class: "Large" },
  { make: "Dassault", model: "Falcon 20F-5BR", class: "Medium" },
  { make: "Dassault", model: "Falcon 50", class: "Large" },
  { make: "Dassault", model: "Falcon 50-40", class: "Large" },
  { make: "Dassault", model: "Falcon 50EX", class: "Large" },
  { make: "Dassault", model: "Falcon 6X", class: "Large" },
  { make: "Dassault", model: "Falcon 7X", class: "Large" },
  { make: "Dassault", model: "Falcon 8X", class: "Large" },
  { make: "Dassault", model: "Falcon 900", class: "Large" },
  { make: "Dassault", model: "Falcon 900B", class: "Large" },
  { make: "Dassault", model: "Falcon 900C", class: "Large" },
  { make: "Dassault", model: "Falcon 900DX", class: "Large" },
  { make: "Dassault", model: "Falcon 900EX", class: "Large" },
  { make: "Dassault", model: "Falcon 900EX EASy", class: "Large" },
  { make: "Dassault", model: "Falcon 900LX", class: "Large" },
  { make: "Eclipse", model: "Eclipse 500", class: "VLJ" },
  { make: "Eclipse", model: "Eclipse 550", class: "VLJ" },
  { make: "Embraer", model: "Legacy 450", class: "Medium" },
  { make: "Embraer", model: "Legacy 500", class: "Medium" },
  { make: "Embraer", model: "Legacy 600", class: "Large" },
  { make: "Embraer", model: "Legacy 650", class: "Large" },
  { make: "Embraer", model: "Legacy 650E", class: "Large" },
  { make: "Embraer", model: "Phenom 100", class: "VLJ" },
  { make: "Embraer", model: "Phenom 100E", class: "VLJ" },
  { make: "Embraer", model: "Phenom 100EV", class: "VLJ" },
  { make: "Embraer", model: "Phenom 300", class: "Light" },
  { make: "Embraer", model: "Phenom 300E", class: "Light" },
  { make: "Embraer", model: "Praetor 500", class: "Medium" },
  { make: "Embraer", model: "Praetor 600", class: "Large" },
  { make: "Gulfstream", model: "G100", class: "Medium" },
  { make: "Gulfstream", model: "G150", class: "Medium" },
  { make: "Gulfstream", model: "G200", class: "Medium" },
  { make: "Gulfstream", model: "G280", class: "Large" },
  { make: "Gulfstream", model: "G300", class: "Large" },
  { make: "Gulfstream", model: "G350", class: "Large" },
  { make: "Gulfstream", model: "G400", class: "Large" },
  { make: "Gulfstream", model: "G450", class: "Large" },
  { make: "Gulfstream", model: "G500", class: "Large" },
  { make: "Gulfstream", model: "G550", class: "Large" },
  { make: "Gulfstream", model: "G600", class: "Large" },
  { make: "Gulfstream", model: "G650", class: "Large" },
  { make: "Gulfstream", model: "G650ER", class: "Large" },
  { make: "Gulfstream", model: "G700", class: "Large" },
  { make: "Gulfstream", model: "G800", class: "Large" },
  { make: "Gulfstream", model: "GII", class: "Large" },
  { make: "Gulfstream", model: "GIII", class: "Large" },
  { make: "Gulfstream", model: "GIV", class: "Large" },
  { make: "Gulfstream", model: "GIVSP", class: "Large" },
  { make: "Gulfstream", model: "GV", class: "Large" },
  { make: "Hawker", model: "1000", class: "Medium" },
  { make: "Hawker", model: "4000", class: "Large" },
  { make: "Hawker", model: "400XP", class: "Light" },
  { make: "Hawker", model: "400XPR", class: "Light" },
  { make: "Hawker", model: "400XT ", class: "Light" },
  { make: "Hawker", model: "400XTi", class: "Light" },
  { make: "Hawker", model: "700", class: "Medium" },
  { make: "Hawker", model: "750", class: "Medium" },
  { make: "Hawker", model: "800A", class: "Medium" },
  { make: "Hawker", model: "800SP", class: "Medium" },
  { make: "Hawker", model: "800XP", class: "Medium" },
  { make: "Hawker", model: "800XPi", class: "Medium" },
  { make: "Hawker", model: "850XP", class: "Medium" },
  { make: "Honda", model: "HA-420", class: "VLJ" },
  { make: "Honda", model: "Elite S", class: "VLJ" },
  { make: "IAI", model: "Westwind 1", class: "Medium" },
  { make: "IAI", model: "Westwind 2", class: "Medium" },
  { make: "Mitsubishi", model: "Diamond 1A", class: "Light" },
  { make: "Nextant", model: "400XT", class: "Light" },
  { make: "Nextant", model: "400XTi", class: "Light" },
  { make: "Nextant", model: "604XT", class: "Large" },
  { make: "Pilatus", model: "PC-24", class: "Medium" },
  { make: "Gulfstream", model: "G400", class: "Large" },
  { make: "Gulfstream", model: "G800", class: "Large" },
  { make: "Nextant", model: "604XT", class: "Large" },
  { make: "Bombardier", model: "Global 5500", class: "Large" },
  { make: "Bombardier", model: "Global 6500", class: "Large" },
  { make: "Honda", model: "Elite S", class: "VLJ" },
  { make: "Beechjet", model: "400XT", class: "Light" },
  { make: "Hawker", model: "400XT", class: "Light" },
];

const API_URL = process.env.NODE_ENV === "development" ? "http://localhost:8000/api/" : "https://dashboard.compareprivateplanes.com/api/";

const USERlEVELS = {
  '68': "owner_dashboard",
  '71': "pro_dashboard",
  '72': "owner_dashboard",
  '73': "owner_search",
  '74': "pro_search",
  '75': "jet_finder",
  '76': "pro_jet_finder",
  '77': "unlimited_jet_finder",
  '78': "professional_jet_finder",
  '79': "technical_annual",
  '80': "jet_finder_annual",
  '81': "new_sep_mid",
  '82': "buyer_annual",
  '83': "basic_monthly",
  '84': "basic_plus_monthly",
  '85': "professional_monthly",
};

const UPGRADE_ACCOUNT_URL = 'https://compareprivateplanes.com/premium/upgrade-account'
const TABLE_BLURED_VALUE = 'blured_value'

export {
  API_URL,
  CATEGORY_OPTIONS,
  MANUFACTURER_OPTIONS,
  PRODUCTION_OPTIONS,
  CATEGORY_OPTIONS_DIC,
  MANUFACTURER_OPTIONS_DIC,
  PRODUCTION_OPTIONS_DIC,
  REGION_OPTIONS,
  CURRENCY_OPTIONS,
  AIRFRAME_OPTIONS,
  FUTURE_OPTIONS,
  UNIT_OPTIONS,
  CLASS_OPTIONS,
  MAKE_OPTIONS,
  MODEL_OPTIONS,
  getCountryFromRegistration,
  MONTH_PREFIXES,
  FORMAT_DATE,
  calculateDistance,
  calculateTimeDifference,
  FILTER_OPTIONS,
  ucFirst,
  USERlEVELS,
  UPGRADE_ACCOUNT_URL,
  AIRCRAFT_REGNUMBER_COUNTRY_PREFIXES,
  TABLE_BLURED_VALUE
};
