import numeral from 'numeral'
import SimpleDonut from '../shared/healthScoreCard/simpleDonut'
import PaginatedTable from '../shared/paginatedTable'
import { useContext, useEffect, useState } from 'react'
import Acquisition from '../SingleAircraft/Acquisition'
import OwnershipCosts from '../SingleAircraft/OwnershipCosts'
import {
  FUTURE_OPTIONS,
  REGION_OPTIONS,
  UPGRADE_ACCOUNT_URL
} from '../../utils/constants/app-constants'
import { Link } from 'react-router-dom'
import AppContext from '../../custom-context/appContext'
import GLBAircraftModelViewer from "../common/GLBAircraftModelViewer";

const BarColors = {
  annual_operating_cost: 'linear-gradient(90deg, #2193B0 0%, #6DD5ED 100%)',
  fixed_costs:
    'linear-gradient(270deg, #9268F7 0%, rgba(146, 104, 247, 0.59) 100%)',
  hourly_cost: 'linear-gradient(90deg, #56AB2F 0%, #A8E063 100%)',
  acquisition_cost:
    'linear-gradient(270deg, #002071 0%, rgba(0, 32, 113, 0.71) 100%)',
  future_sale_value_costs: 'linear-gradient(90deg, #EB3349 0%, #F45C43 100%)',
  total_ownership_cost:
    'linear-gradient(270deg, #002071 0%, rgba(0, 32, 113, 0.71) 100%)',
  cost_per_year: 'linear-gradient(90deg, #EB3349 0%, #F45C43 100%)'
}

const BlurredTab = () => {
  return (
    <div className='size-full flex justify-center items-center p-2 bg-[#b5b5b5a3] backdrop-blur rounded-lg'>
      <a
        href={UPGRADE_ACCOUNT_URL}
        rel='noreferrer'
        target='_blank'
        className='px-3 py-2 rounded-xl flex justify-center items-center gap-2 !bg-[#907EFF] !text-white text-center capitalize max-lg:text-nowrap text-sm'
      >
        <p>Upgrade</p>
        <i className='fa-solid fa-up-right-from-square !text-white'></i>
      </a>
    </div>
  )
}

const AircraftCard = ({ aircraft }) => {
  const { userLevel } = useContext(AppContext)
  const hasLimitedAccess = !['professional_jet_finder', 'jet_finder_annual', 'buyer_annual', 'technical_annual'].includes(userLevel)
  const [acquisitionAirframe, setAacquisitionAirframe] = useState(500)
  const [yearManufacture, setYearManufacture] = useState('Select')
  const [estimatedFutureValue, setestimatedFutureValue] = useState(
    FUTURE_OPTIONS[0]
  )
  const [aircraftCostValues, setAircraftCostValues] = useState({})
  const tabs = [
    'costs',
    'missions',
    'features',
    'specs'
  ]
  const [currentTabkey, setCurrentTabKey] = useState(
    tabs.length !== 0 ? tabs[0] : ''
  )
  const [tabContent, setTabContent] = useState('')
  const [nbHours, setNbHours] = useState(0)
  const [conversionRate, setConversionRate] = useState(1)
  const [region, setRegion] = useState(REGION_OPTIONS[0])
  const currencySymbol = '$'
  useEffect(() => {
    const costs = {
      annual_operating_cost: numeral(aircraft.annual_operating_cost).format(
        '0,0.0'
      ),
      fixed_costs: numeral(aircraft.NA_annual_total).format('0,0.0'),
      hourly_cost: numeral(aircraft.NA_hourly_total).format('0,0.0'),
      acquisition_cost: numeral(aircraft.average_pre_owned).format('0,0.0'),
      future_sale_value_costs: numeral(aircraft.future_sale_value_costs).format(
        '0,0.0'
      ),
      total_ownership_cost: numeral(aircraft.total_ownership_cost).format(
        '0,0.0'
      ),
      cost_per_year: numeral(aircraft.cost_per_year).format('0,0.0')
    }
    const specs = [
      {
        variable: 'Maximum Range',
        value: `${numeral(aircraft.range_NM).format('0,0')} nautical miles`
      },
      {
        variable: 'High-Speed Cruise',
        value: `${numeral(aircraft.high_cruise_knots).format('0,0')} Knots`
      },
      {
        variable: 'Long-Range Cruise',
        value: `${numeral(aircraft.long_range_cruise_knots).format(
          '0,0'
        )} Knots`
      },
      {
        variable: 'Typical Passengers',
        value: `${numeral(aircraft.typical_pax).format('0,0')}`
      },
      {
        variable: 'Hourly Fuel Burn',
        value: `${numeral(aircraft.hourly_fuel_burn_GPH).format(
          '0,0'
        )} gallons per hour`
      },
      {
        variable: 'Max Altitude',
        value: `${numeral(aircraft.max_altitude_feet).format('0,0')} feet`
      },
      {
        variable: 'Minimum Landing Distance',
        value: `${numeral(aircraft.TO_distance_feet).format('0,0')} feet`
      },
      {
        variable: 'Max Cabin Altitude',
        value: `${numeral(aircraft.cabin_altitude).format('0,0')} feet`
      },
      {
        variable: 'Powerplant',
        value: `${aircraft.engine_manufacturer}`
      },
      {
        variable: 'Baggage Capacity',
        value: `${numeral(aircraft.baggage_capacity_CF).format(
          '0,0'
        )} cubic feet`
      }
    ]
    if (currentTabkey) {
      if (currentTabkey === 'costs') {
        setTabContent(
          <div className='flex flex-col justify-center gap-8 h-full'>
            <div className='flex flex-col gap-[6px]'>
              <h6 className='mb-2 text-2xl'>
                Cost Over {Math.round(aircraft.annual_hours)} annual flight
                hours
              </h6>
              <Bar
                title={'annual operating cost'}
                number={costs.annual_operating_cost}
                width={95}
              />
              <Bar
                title={'fixed costs'}
                number={costs.fixed_costs}
                width={65}
                blur={hasLimitedAccess}
              />
              <Bar
                title={'hourly cost'}
                number={costs.hourly_cost}
                width={45}
                blur={hasLimitedAccess}
              />
            </div>
            <div className='flex flex-col gap-[6px]'>
              <Bar
                title={'acquisition cost'}
                number={costs.acquisition_cost}
                width={100}
              />
              <Bar
                title={'future sale value costs'}
                number={costs.future_sale_value_costs}
                width={80}
              />
            </div>
            <div className='flex flex-col gap-[6px]'>
              <h6 className='mb-2 text-2xl'>
                Cost Over {aircraft.ownership_years} Years Inc. Sale
              </h6>
              <Bar
                title={'total ownership cost'}
                number={costs.total_ownership_cost}
                width={100}
              />
              <Bar
                title={'cost per year'}
                number={costs.cost_per_year}
                width={55}
                blur={hasLimitedAccess}
              />
            </div>
          </div>
        )
      } else if (currentTabkey === 'missions') {
        aircraft.missions.length === 0 &&
          aircraft.missions.push({
            from: '',
            to: '',
            reason: '',
            isMissionsEmpty: true
          })
        setTabContent(<PaginatedTable data={aircraft.missions} />)
      } else if (currentTabkey === 'features') {
        setTabContent(<PaginatedTable data={aircraft.features} />)
      } else if (currentTabkey === 'specs') {
        setTabContent(<PaginatedTable data={specs} />)
      } else if (currentTabkey === 'acquisition_costs') {
        setTabContent(
          hasLimitedAccess ? (
            <BlurredTab />
          ) : (
            <div className='size-full overflow-auto'>
              <Acquisition
                airframe={acquisitionAirframe}
                setAirframe={setAacquisitionAirframe}
                yearManufacture={yearManufacture}
                setYearManufacture={setYearManufacture}
                estimatedFutureValue={estimatedFutureValue}
                setestimatedFutureValue={setestimatedFutureValue}
                isAiSearchAircraftPage={true}
                params={aircraft}
                conversionRate={conversionRate}
                currencySymbol={currencySymbol}
              />
            </div>
          )
        )
      } else if (currentTabkey === 'ownership_costs') {
        setTabContent(
          hasLimitedAccess ? (
            <BlurredTab />
          ) : (
            <div className='size-full overflow-auto'>
              <OwnershipCosts
                key={aircraftCostValues?.['annualTotal']}
                setNbHours={setNbHours}
                nbHours={nbHours}
                aircraftCostValues={aircraftCostValues}
                setAircraftCostValues={setAircraftCostValues}
                isAiSearchAircraftPage={true}
                params={aircraft}
                currencySymbol={currencySymbol}
                region={region}
                conversionRate={conversionRate}
                setNbHoursProp={setNbHours}
              />
            </div>
          )
        )
      } else {
        setTabContent('nothing')
      }
    }
  }, [
    currentTabkey,
    aircraft,
    acquisitionAirframe,
    yearManufacture,
    estimatedFutureValue,
    aircraftCostValues,
    nbHours
  ])
  const Bar = ({ title, number, width, blur = false }) => {
    const bgColor = BarColors[title.replaceAll(' ', '_')]
    const barStyle = {
      width: `${width}%`,
      maxWidth: '100%',
      minWidth: '70px',
      background: bgColor
        ? bgColor
        : 'linear-gradient(270deg, #002071 0%, rgba(0, 32, 113, 0.71) 100%)'
    }
    return (
      <div
        className='text-xs lg:text-sm 2xl:text-lg h-6 rounded-r-2xl text-white px-2 py-1 flex items-center justify-between gap-2'
        style={barStyle}
      >
        <span className='capitalize'>
          {title ? title.replaceAll('_', ' ') : ''}
        </span>
        {blur ? (
          <span className='blur-sm'>$000</span>
        ) : (
          <span className={blur ? 'blur-sm' : ''}>
            ${numeral(number).format('0,0')}
          </span>
        )}
      </div>
    )
  }
  return (
    <div
      className='flex w-full bg-white rounded-xl overflow-hidden *:py-5 max-lg:flex-col'
      style={{ boxShadow: '0px 28px 56.2px -20px #00000040' }}
    >
      <div
        className='lg:w-4/6 py-5 flex rounded-xl max-lg:flex-col max-lg:px-7 lg:pl-7'
        style={{ boxShadow: '28px 0px 56.2px -20px #00000012' }}
      >
        <div className='flex-1 flex flex-col gap-4'>
          <h4 className='text-3xl'>{aircraft?.aircraft_name}</h4>
          <p className='text-2xl'>
            {aircraft?.production_start} - {aircraft?.production_end}
          </p>
          <div className={`w-full aspect-[500/192] max-h-100`}>
              <GLBAircraftModelViewer src={aircraft.aircraft_3d_model} poster={aircraft.aircraft_image} />
            </div>
        </div>
        <div className='flex-1 flex gap-2 max-lg:flex-col'>
          <div className='grid grid-cols-2 max-lg:grid-cols-4 gap-4 lg:w-3/4'>
            <SimpleDonut
              title={'overall match'}
              percentage={Math.round(aircraft?.overall_match) || 0}
            />
            <SimpleDonut
              title={'mission match'}
              percentage={aircraft?.missions_score || 0}
            />
            <SimpleDonut
              title={'feature match'}
              percentage={aircraft?.features_score || 0}
            />
            <SimpleDonut
              title={'budget match'}
              percentage={aircraft?.budget_score || 0}
            />
          </div>
          <div className='lg:w-1/4 flex lg:flex-col lg:justify-center items-center gap-4 pr-2 max-lg:overflow-x-auto scroll-smooth scrollbar-size max-lg:pb-[20px]'>
            {tabs.map((key, i) => {
              return (
                <div
                  key={i}
                  className={`${
                    key === currentTabkey ? 'bg-[#F2F2F2]' : ''
                  } interact_button hover:bg-[#F2F2F2] justify-center lg:w-full text-center capitalize max-lg:text-nowrap`}
                  onClick={() => setCurrentTabKey(key)}
                >
                  <p>{key.replaceAll('_', ' ')}</p>
                </div>
              )
            })}
            <Link
              to={`/aircraft?id=${aircraft.id}`}
              target='_blank'
              className={`px-2 py-3 rounded-xl flex justify-center items-center gap-2 !bg-[#907EFF] !text-white lg:w-full text-center capitalize max-lg:text-nowrap text-sm`}
            >
              <p>Learn More</p>
              <i className='fa-solid fa-up-right-from-square !text-white'></i>
            </Link>
          </div>
        </div>
      </div>
      <div
        className={`relative lg:w-2/6 pl-4 pr-2 flex flex-col h-[500px] ${
          ['acquisition_costs', 'ownership_costs'].includes(currentTabkey)
            ? 'select-none overflow-hidden'
            : 'overflow-y-scroll'
        }`}
      >
        {tabContent}
      </div>
    </div>
  )
}

export default AircraftCard
