import { useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AppContext from "../../../custom-context/appContext";
import { StateContext } from "../../../context";
import cn from "classnames";
import styles from "./Nav.module.scss";
import NavItem from "./navItem";

const Header = () => {
  const { numLiveFlights, isSidenavOpen, toggleSidenav } = useContext(StateContext);
  const { userLevel } = useContext(AppContext); // Extracting user level
  const location = useLocation();
  const navigate = useNavigate();

  const periodOfDay = () => {
    const currentTime = new Date();
    const hour = currentTime.getHours();

    if (hour < 12) {
      return "Morning";
    } else if (hour < 18) {
      return "Afternoon";
    } else {
      return "Evening";
    }
  }

  const navigateToUrl = (url) => {
    toggleSidenav();
    setTimeout(() => {
      navigate(url);
    }, 100);
  }


  // List of roles allowed to access each menu item
  const allowedRoles = {
    dashboard: ["basic_dashboard", "pro_dashboard", "owner_dashboard", "pro_jet_finder", "unlimited_jet_finder", "jet_finder", "professional_jet_finder"],
    lookup: ["pro_dashboard", "owner_dashboard", "pro_jet_finder", "unlimited_jet_finder", "jet_finder", "professional_jet_finder"],
    airports: ["pro_dashboard", "owner_dashboard", "pro_jet_finder", "unlimited_jet_finder", "jet_finder", "professional_jet_finder"],
    operators: ["pro_dashboard", "owner_dashboard", "pro_jet_finder", "unlimited_jet_finder", "jet_finder", "professional_jet_finder"],
    jetFinder: ["pro_jet_finder", "technical_annual", "unlimited_jet_finder", "buyer_annual", "jet_finder", "professional_jet_finder", "jet_finder_annual"],
    directory: ["pro_jet_finder", "unlimited_jet_finder", "jet_finder", "professional_jet_finder"],
    search: ["technical_annual", "buyer_annual", "new_sep_mid", "pro_search", "pro_jet_finder", "unlimited_jet_finder", "jet_finder", "professional_jet_finder"],
    sharedLinks: ["buyer_annual", "new_sep_mid"]
  };

  const canAccess = (allowedRoles) => allowedRoles.includes(userLevel); // Function to check access

  return (
    <div className={cn(styles.sidenav, `${isSidenavOpen ? "flex md:hidden" : "hidden"} px-8 py-6 flex-col gap-4 shrink-0`)}>
      <div className="mb-6 mt-2 flex flex-col gap-2">
       {/*<h3 className="text-lg">Good {periodOfDay()}, {userName}</h3>*/}

        {/*<div className={`${cn(styles.sub_item)} flex md:hidden`}>
          <div className={cn(styles.status_icon)} />
          <p>Status</p>
        </div>*/}

        <div className={`${cn(styles.nav_item)} flex md:hidden`}>
         {/* <div className={cn(styles.sub_item)}>
            <p><i className="fa-regular fa-paper-plane"></i></p>
            <p>{numLiveFlights}&nbsp;Live Flights</p>
          </div>*/}
        </div>
      </div>

      {canAccess(allowedRoles.dashboard) && (
        <NavItem navigateToUrl={navigateToUrl} link="/" icon="house" text='Dashboard' currentPath={location.pathname} />
      )}
      {canAccess(allowedRoles.lookup) && (
        <NavItem navigateToUrl={navigateToUrl} link="/lookup" icon="binoculars" text='Lookup' currentPath={location.pathname} />
      )}
      {canAccess(allowedRoles.airports) && (
        <NavItem navigateToUrl={navigateToUrl} link="/airport-lookup" icon="plane-departure" text='Airports' currentPath={location.pathname} />
      )}
      {canAccess(allowedRoles.operators) && (
        <NavItem navigateToUrl={navigateToUrl} link="/operators" icon="earth-americas" text='Operators' currentPath={location.pathname} />
      )}
      {canAccess(allowedRoles.jetFinder) && (
        <NavItem navigateToUrl={navigateToUrl} link="/jet-finder" icon="search" text='Jet Finder' currentPath={location.pathname} />
      )}
      {canAccess(allowedRoles.directory) && (
        <NavItem navigateToUrl={navigateToUrl} link="/aircrafts" icon="folder-open" text='Directory' currentPath={location.pathname} />
      )}
      {canAccess(allowedRoles.search) && (
        <NavItem navigateToUrl={navigateToUrl} link="/search" icon="plane" text='Search Aircraft' currentPath={location.pathname} />
      )}
      {canAccess(allowedRoles.sharedLinks) && (
        <NavItem navigateToUrl={navigateToUrl} link="/shared-links" icon="share" text='Shared Links' currentPath={location.pathname} />
      )}

      <div className={cn(styles.divider)}  />
      <NavItem navigateToUrl={navigateToUrl} link="https://compareprivateplanes.com/register/your-membership" icon="user" text='Account' />
      <NavItem navigateToUrl={navigateToUrl} link="https://compareprivateplanes.com/wp-login.php?action=logout&redirect_to=https%3A%2F%2Fcompareprivateplanes.com&_wpnonce=892bc803fb" icon="right-from-bracket" text='Logout' />
    </div>
  );
};

export default Header;
