import React from "react";
import { RouterProvider, createBrowserRouter, useSearchParams } from "react-router-dom";
import { StateProvider } from "./context";

import Layout from "./views/layout";
import Home from "./views/dashboard";
import Search from "./views/Search";
import CompareAircrafts from "./views/compareAircrafts";
import Sample from "./views/Sample/Sample";
import NotFound from "./views/NotFound/NotFound";
import NotAuthorized from "./views/NotAuthorized";
import AircraftLookup from "./views/aircraftLookup";
import SingleAircraftDetails from "./views/SingleAircraft/SingleAircraftDetails";
import useUserAuthentication from "./custom-hooks/useUserAuthentication";
import "./styles/global.module.scss";
import "./styles/index.css";
import authStyles from "./styles/authentication.module.scss";
import useQuestionLimitation from "./custom-hooks/useQuestionLimitation";
import AppContext from "./custom-context/appContext";
import LookupAirport from "./views/airportLookup";
import Operators from "./views/operator";
import AiArcraftSearch from "./views/aiArcraftSearch";
import AllAircrafts from "./views/AllAircrafts";
import SharedPage from "./views/SharedPage";
import { ToastContainer } from "react-toastify";
import SharedLinks from "./views/SharedLinks";
import OnePageLayout from "./components/shared/OnePageLayout";

function App() {
  const { isAuthenticating, isAuthenticated, userLevel } =
    useUserAuthentication();
  const useQuestionLimitations = useQuestionLimitation();
  const IsOwnerProSearchRoles = ['pro_jet_finder', 'unlimited_jet_finder', 'pro_search'].includes(userLevel);
  const IsAllPageAccessRoles = ['pro_jet_finder', 'unlimited_jet_finder', 'jet_finder', 'professional_jet_finder'].includes(userLevel);
  const NewSearchRoles = ['technical_annual', 'new_sep_mid'].includes(userLevel);
  const BuyerAnnual = ['buyer_annual'].includes(userLevel);
  const TechnicalJetFinder = ['technical_annual'].includes(userLevel);
  const JetFinderAnnual = ['jet_finder_annual'].includes(userLevel);
  const IsOtherRoles = ['basic_dashboard','pro_search'].includes(userLevel)
  // Check specifically for technical_annual and new_sep_mid user level
  const isNewSepBasic = userLevel === 'technical_annual';
  // Edited routes to be displayed only with the correct userLevel. *Garvit*
  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        IsAllPageAccessRoles ? (
          <Layout>
            <Home />
          </Layout>
        ) : IsOwnerProSearchRoles ? (
          <Layout>
            <Search />
          </Layout>
        ) : IsOwnerProSearchRoles ? (
          <Layout>
            <AiArcraftSearch />
          </Layout>
        ) : (
            <NotAuthorized />
        )
      ),
    },
    {
      path: "/jet-finder",
      element: (
        IsAllPageAccessRoles || BuyerAnnual || TechnicalJetFinder || JetFinderAnnual ? (
          <Layout>
          <AiArcraftSearch />
        </Layout>
        ) : (
          <NotAuthorized />
        )
      ),
    },
    {
      path: "/aircrafts",
      element: (
        IsAllPageAccessRoles ? (
          <Layout>
            <AllAircrafts />
          </Layout>
        ) : (
          <NotAuthorized />
        )
      ),
    },
    {
      path: "/lookup",
      element:
        !IsOtherRoles ? (
          <Layout>
            <AircraftLookup />
          </Layout>
        ) : (
          <NotAuthorized />
        ),
    },
    {
      path: "/search",
      element:
      IsOwnerProSearchRoles || IsAllPageAccessRoles || BuyerAnnual || NewSearchRoles ? (
          <Layout>
            <Search />
          </Layout>
        ) : (
          <NotAuthorized />
        ),
    },
    {
      path: "/aircraft",
      element: IsOwnerProSearchRoles || BuyerAnnual || IsAllPageAccessRoles || NewSearchRoles ? (
        <OnePageLayout 
          sectionIds={isNewSepBasic 
            ? ["overview", "technical", "similar_aircraft"] // Exclude "flight_activity" for technical_annual
            : ["overview", "ownership_costs", "acquisition_costs", "technical", "similar_aircraft"] // Include all sections for other roles
          }
        >
          <SingleAircraftDetails />
        </OnePageLayout>
      ) : (
        <NotAuthorized />
      ),
    },
    {
      path: "/airport-lookup",
      element:
        !IsOtherRoles? (
          <Layout>
            <LookupAirport />
          </Layout>
        ) : (
          <NotAuthorized />
        ),
    },
    {
      path: "/operators",
      element:
        !IsOtherRoles ? (
          <Layout>
            <Operators />
          </Layout>
        ) : (
          <NotAuthorized />
        ),
    },
    {
      path: "/compare",
      element:
      IsAllPageAccessRoles || BuyerAnnual || IsAllPageAccessRoles || NewSearchRoles ? (
          <OnePageLayout sectionIds={isNewSepBasic 
            ? ["basic","performance","range_map"] // Exclude "flight_activity" for technical_annual
            : ["basic","performance","ownership_costs","acquisition_costs","range_map"] // Include all sections for other roles
          }
        >
            <CompareAircrafts />
          </OnePageLayout>
        ) : (
          <NotAuthorized />
        ),
    },
    { path: "/sample", element: (
        <Layout>
          <Sample />
        </Layout>
      )
    },
    { path: "/shared-links", element: (
        <Layout>
          <SharedLinks />
        </Layout>
      )
    },
    { path: "/share/:sharedId", element: (
        <Layout>
          <SharedPage />
        </Layout>
      )
    },
    {
      path: "/not-authorized",
      element: <NotAuthorized />,
      errorElement: <NotFound />,
    },
    { path: "*", element: <NotFound /> },
  ]);
  const pathStartWith = (path)=>window.location.pathname.startsWith(path)
  return (
    <StateProvider>
      {/** Adding user level to the app context. *Garvit* */}
      <AppContext.Provider value={{ userLevel, ...useQuestionLimitations }}>
        {isAuthenticating ? (
          <div className={authStyles.mainwrapper}>Authenticating...</div>
        ) : pathStartWith('/share/') || pathStartWith('/sample') || isAuthenticated ? (
          <>
            <RouterProvider router={router} />
            <ToastContainer position="top-right" />
          </>
         ) : (
          <NotAuthorized />
        )}
      </AppContext.Provider>
    </StateProvider>
  );
}

export default App;
